<template>
  <div>
    <ViewTemplateWithTable :panel="panel" :templateList="templateList" :filters="filters">
      <div slot="content-buttons">
        <Button _key="btnUserCreate" title="Usuário" classIcon="fas fa-plus-circle" type="primary" size="small"
          eventName="userCreate" :clicked="create" />
      </div>
      <div slot="content-buttons-table-header">
        <Button _key="btnDisconnectUser" :title="'Desconectar ' + selected.length" type="primary" size="small"
          :clicked="disconnect" />
      </div>
    </ViewTemplateWithTable>
  </div>
</template>

<script>
import ViewTemplateWithTable from "@nixweb/nixloc-ui/src/component/template/ViewTemplateWithTable.vue";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";

import { mapGetters, mapMutations, mapState } from "vuex";

export default {
  name: "UsuarioListaView",
  components: {
    ViewTemplateWithTable,
    Button,
  },
  data() {
    return {
      panel: {
        module: "Adm",
        title: "Usuário",
        showFilter: true,
        showSearch: true,
        showButtons: true,
      },
      templateList: {
        urlGetApi: "/api/v1/adm/user/get-all",
        urlDeleteAllApi: "/api/v1/adm/user/delete",
        showChecks: true,
        headerTable: [
          {
            field: "name",
            title: "Nome",
            type: "link",
            iconSearch: true,
            routeName: "userUpdate",
          },
          {
            field: "userName",
            title: "Login",
            type: "text",
            iconSearch: false,
          },
          {
            field: "email",
            title: "E-mail",
            type: "text",
            iconSearch: false,
          },
          {
            field: "typeName",
            title: "Tipo",
            type: "class",
            fieldComparison: "typeName",
            classCssTitle: "text-center",
            classCssBody: [
              {
                classCss: "badge badge-info text-center",
                fieldComparison: "Adm",
              },
              { classCss: "badge badge-warning text-center", fieldComparison: "Padrão" },
            ],
          },
          {
            field: "statusName",
            title: "Status",
            type: "class",
            fieldComparison: "statusName",
            classCssTitle: "text-center",
            classCssBody: [
              {
                classCss: "badge badge-primary text-center",
                fieldComparison: "Ativo",
              },
              { classCss: "badge badge-danger text-center", fieldComparison: "Inativo" },
            ],
          },
          {
            field: "isConnected",
            title: "Conexão",
            type: "html",
            iconSearch: false,
          },
        ],
      },
      filters: [
        {
          type: "options",
          title: "Status",
          field: "StatusName",
          options: [
            { text: "Ativo", value: "Ativo" },
            { text: "Inativo", value: "Inativo" },
          ],
          value: [],
        },
        {
          type: "options",
          title: "Tipo",
          field: "TypeName",
          options: [
            { text: "Adm", value: "Adm" },
            { text: "Padrão", value: "Padrão" },
          ],
          value: [],
        },
      ],
    };
  },
  computed: {
    ...mapGetters("generic", ["event"]),
    ...mapState("generic", ["selected"]),
  },
  methods: {
    ...mapMutations("generic", ["addEvent", "removeLoading"]),
    create() {
      this.$router.push({
        name: "userCreate",
      });
      this.removeLoading(["btnUserCreate"]);
    },
    disconnect() {
      var event = { name: "disconnectUsers", data: this.selected };
      this.addEvent(event);

      let self = this;
      setTimeout(function () {
        self.removeLoading(["btnDisconnectUser"]);
      }, 200);

    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "userUpdate") {
          let self = this;
          setTimeout(function () {
            self.$router.push({
              name: "userUpdate",
              params: { id: event.data.id },
            });
            self.removeLoading([event.data.id]);
          }, 300);
        }
      },
      deep: true,
    },
  },
};
</script>
<style>
.box-user {
  width: 10px;
  height: 10px;
  border-radius: 50px;
  margin-right: 8px;
}

.disconnected {
  background-color: red;
}

.connected {
  background-color: green;
}
</style>
